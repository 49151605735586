var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useGetPortfolioQuery } from '@shared/api/portfolio';
import { mapPortfolioFromResponse, usePortfolioOutletContext } from '@entities/portfolio';
import { ContainerRow } from '@src/components/styled';
import { PortfolioOverview } from '@features/portfolio/portfolio-overview';
import CurrentPositionsTable from './CurrentPositionsTable';
const Container = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const PortfolioCurrentPositions = () => {
    const { portfolioId } = usePortfolioOutletContext();
    const { currentData: portfolio, isLoading } = useGetPortfolioQuery({ portfolioId }, {
        skip: typeof portfolioId === 'undefined',
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: currentData ? mapPortfolioFromResponse(currentData) : undefined }));
        },
    });
    return (_jsxs(Container, { children: [portfolio && (
            // TODO-FIX: add skeletons & loading state
            _jsx(PortfolioOverview, { portfolio: portfolio, 
                // TODO-FIX: add after b2q & subscription enabling
                isCopyAvailable: false, isLoading: isLoading })), _jsx(CurrentPositionsTable, { portfolioId: String(portfolioId) })] }));
};
export default PortfolioCurrentPositions;
